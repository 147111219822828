<template>
  <div>
    <q-form ref="editForm">
      <!-- 관리감독자 업무일지 기본정보 -->
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <c-plant
              required
              :disabled="disabled || isOld"
              :editable="editable"
              type="none"
              name="plantCd"
              v-model="safetyCheck.plantCd">
            </c-plant>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
            <!-- 점검일 -->
            <c-datepicker
              required
              :disabled="disabled"
              :editable="editable"
              label="점검일"
              name="checkDate"
              v-model="safetyCheck.checkDate">
            </c-datepicker>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- 점검부서/점검자 -->
            <c-field
              required
              :disabled="disabled"
              :editable="editable"
              :data="safetyCheck"
              :isDeptManager="true"
              deptValue="checkDeptCd"
              type="dept_user"
              label="부서/관리감독자"
              name="checkUserId"
              v-model="safetyCheck.checkUserId">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <!-- 점검명 -->
            <c-text
              required
              :disabled="disabled"
              :editable="editable"
              label="점검명"
              name="safetyCheckName"
              v-model="safetyCheck.safetyCheckName">
            </c-text>
          </div>
        </template>
      </c-card>
      <c-table
        ref="table"
        title="점검결과 목록"
        :columns="grid.columns"
        :data="safetyCheck.resultList"
        :usePaging="false"
        :hideBottom="true"
        :isTitle="true"
        gridHeaderLabel="점검결과"
        :columnSetting="false"
        :gridHeightAuto="true"
        :isFullScreen="false"
        :filtering="false"
        :isExcelDown="false"
        selection="multiple"
        rowKey="safetyCheckResultId"
        :editable="editable"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn v-if="editable" label="추가" icon="add" @btnClicked="addResult" />
            <c-btn v-if="editable" label="제외" icon="remove" @btnClicked="removeResult" />
          </q-btn-group>
        </template>
      </c-table>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <!-- 삭제 -->
          <c-btn 
            :flat="true"
            v-if="editable && !disabled && isOld" 
            label="삭제" 
            icon="delete_forever" 
            @btnClicked="removeData" />
          <!-- 저장 -->
          <c-btn
            :flat="true"
            v-if="editable && !disabled"
            :url="saveUrl"
            :isSubmit="isSave"
            :param="safetyCheck"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveData"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'safetyCheckDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        safetyCheckId: '',
      }),
    },
  },
  data() {
    return {
      grid: {
        columns: [
          {
            required: true,
            name: 'safetyContents',
            field: 'safetyContents',
            label: '점검내용',
            type: 'textarea',
            style: 'width:80%',
            align: 'left',
            sortable: false
          },
          {
            name: 'attach',
            field: 'attach',
            label: '관련 파일',
            align: 'center',
            style: 'width:20%',
            type: 'attach',
            taskClassCd: 'SAFETY_CHECK_RESULT',
            keyText: 'safetyCheckResultId',
            sortable: false,
          },
        ],
      },
      safetyCheck: {
        safetyCheckId: '',  // 
        plantCd: '',  // 사업장 코드
        checkStatusCd: '',  // 계획수립/점검중/점검완료
        checkDeptCd: '',  // 점검부서
        checkUserId: '',  // 점검자 ID
        checkDate: '',  // 점검월
        safetyCheckName: '',  // 점검명

        resultList: [],
        deleteResultList: [],
      },
      editable: true,
      saveUrl: '',
      deleteUrl: '',
      isSave: false,
      isComplete: false,
      completeUrl: '',
      mappingType: 'POST',
      detailUrl: '',
      popupOptions: {
        isFull: true,
        // width: '80%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.safetyCheckId);
    },
    disabled() {
      return this.safetyCheck.checkStatusCd === 'MCSC000015'
    },
  },
  watch: {  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.safetycheck.plan.get.url;
      this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
      this.completeUrl = transactionConfig.sop.safetycheck.plan.complete.url;
      this.deleteUrl = transactionConfig.sop.safetycheck.plan.delete.url;
      // code setting
      // list setting
      this.getDetail()
    },
    getDetail() {
      if (this.popupParam.safetyCheckId) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.safetyCheckId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.safetyCheck =  _result.data;
        },);
      } else {
        this.safetyCheck.checkDate = this.$comm.getToday();
        this.safetyCheck.checkUserId = this.$store.getters.user.userId;
        this.safetyCheck.checkDeptCd = this.$store.getters.user.deptCd;
      }
    },
    saveData() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.safetycheck.plan.insert.url;
        this.mappingType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {            
          if (this.$comm.validTable(this.grid.columns, this.safetyCheck.resultList)) {
          window.getApp.$emit('CONFIRM', {
            title: '확인',
            message: '저장하시겠습니까?', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.safetyCheck.regUserId = this.$store.getters.user.userId;
              this.safetyCheck.chgUserId = this.$store.getters.user.userId;
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.popupParam.safetyCheckId = result.data
      this.getDetail();
    },
    
    removeData() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.safetyCheckId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    addResult() {
      this.safetyCheck.resultList.push({
        safetyCheckId: this.popupParam.safetyCheckId,
        safetyCheckResultId: uid(),
        safetyContents: '',
        editFlag: 'C',
      })
    },
    removeResult() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, item => {
          this.safetyCheck.resultList = this.$_.reject(this.safetyCheck.resultList, { safetyCheckResultId: item.safetyCheckResultId })
        })
        this.$_.forEach(selectData, item => {
          if (!this.safetyCheck.deleteResultList) {
              this.safetyCheck.deleteResultList = []
          }
          if (this.$_.findIndex(this.safetyCheck.deleteResultList, { safetyCheckResultId: item.safetyCheckResultId }) === -1
          && item.editFlag !== 'C') {
            this.safetyCheck.deleteResultList.push(item)
          }
          this.safetyCheck.resultList = this.$_.reject(this.safetyCheck.resultList, 
          { safetyCheckResultId: item.safetyCheckResultId })
        })
      }
    },
    closePopUps() {
      this.$emit('closePopup')
    },
  }
};
</script>
